<template>
    <div class="hero">
        <video playsinline autoplay muted loop :poster="require('@/assets/img/referencia.jpg')" id="bgvideo" ref="releaseVideo">
            <source :src="require('@/assets/video/derechovital_b500k.webm')" type="video/webm">
        </video>
        <div class="hero__content">
          <div :style="`font-size: ${$vuetify.breakpoint.smAndDown ? 1.5 : 3}em`"
            class="font-weight-bold hero__content--title">Derecho Vital</div>
          <div :style="`font-size: ${$vuetify.breakpoint.smAndDown ? 1.2 : 2.4}em`">Nuevo sencillo</div>
          <div :style="`font-size: ${$vuetify.breakpoint.smAndDown ? 0.8 : 1.6}em`" class="smoothlinks">
            Disponible en <a href="https://www.youtube.com/watch?v=vUwFjAHsHj8">Youtube</a>, 
            <a href="https://www.instagram.com/p/CFIr44TJogh/">Instagram</a> y 
            <a href="https://www.facebook.com/ilpakamanibanda/videos/2773384462896269">Facebook</a>
          </div>
          <v-btn color="accent" class="mt-6 d-block" @click="$emit('open-video', 'vUwFjAHsHj8')">Ver aquí
            <v-icon color="white" class="youtube ml-4" x-large>mdi-youtube</v-icon>
          </v-btn>
          <v-btn color="accent" class="mt-6 d-block" @click="$emit('open-link', 'https://open.spotify.com/album/6h1jQEo618dxX2I12PMy0h')">Escuchar
            <v-icon color="white" class="youtube ml-4" x-large>mdi-spotify</v-icon>
          </v-btn>
        </div>
    </div>
</template>

<script>
export default {
  name: 'IlpaNewReleaseDerechoVital',
  mounted() {
    this.$nextTick().then(() => {
      this.$refs.releaseVideo.playbackRate = 0.8;
    })
  },
}
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
.hero {
  position: relative;
  overflow-x: hidden;
  &__content {
    font-family: $font-accent !important;
    // background-color: rgba(black, 0.5);
    // box-shadow: 0 0 10px 10px (black, 0.5);
    // border-radius: 10px;
    padding: 30px;
    position: absolute;
    width: clamp(300px, 50%, 500px);
    top: 50%;
    left: 10%;
    transform: translateY(-50%);
    ::v-deep .v-icon {
      &:hover {
        // transform: scale(1.3);
      }
    }
    > div {
      font-size: 2em;
    }
    &--title {
      font-family: 'Montserrat', sans-serif;
      text-transform: uppercase;
    }
  }
}
::v-deep .v-btn.accent {
  &:hover {
    transform: scale(1.05);
  }
}
video {
  opacity: 0.4;
  margin-left: 50%;
  transform: translateX(-50%);
  width: clamp(700px, 100%, 100%);
}
.smoothlinks > a {
  text-decoration: none;
  color: inherit;
  transition: color .15s ease;
  &:hover {
    color: lighten($ilpa-red, 30%);
  }
}
</style>