<template>
    <div>
        <div :style="`font-size: ${Math.round(mainFontSize*0.4)}px`">{{preface}}</div>
        <transition name="phrase-transition" mode="out-in">
            <div class="phrase" :key="currentPhrase" :style="`font-size: ${mainFontSize}px`">
                <div v-if="currentPhrase === ''" style="opacity: 0"></div>
                <div v-else>{{currentPhrase}}</div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props: ["delay", "preface", "phrases"],
    data() {
        return {
            interval: undefined,
            phraseIndex: 0
        }
    },
    mounted() {
        this.interval = setInterval(() => {
            this.phraseIndex = Math.min(this.phraseIndex + 1, this.phrases.length - 1)
        }, this.delay)
    },
    computed: {
        currentPhrase() {
            return this.phrases[this.phraseIndex]
        },
        mainFontSize() {
            const step = 17
            const maxSize = 110
            const fontSizes = {
                xs: maxSize - step*4,
                sm: maxSize - step*3,
                md: maxSize - step*2,
                lg: maxSize - step,
                xl: maxSize
            }
            return fontSizes[this.$vuetify.breakpoint.name]

            // switch (this.$vuetify.breakpoint.name) {
            //     case "xs":
            //         return "1.6em"
            //     case "sm":
            //         return "2.2em"
            //     case "md":
            //         return "2.2em"
            //     case "lg":
            //         return "3em"
            //     case "xl":
            //         return "3em"
            //     default:
            //         return "1.6em"
            // }
        },
    }
}
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css?family=Fredericka+the+Great&display=swap");

.phrase {
    font-family: "Fredericka the Great", cursive !important;
    font-weight: 300;
    line-height: 0.85;
}

.phrase-transition-enter-active {
    transition: opacity 0.2s ease-in-out;
}
.phrase-transition-leave-active {
    transition: opacity 0.2s ease-in-out;
}
.phrase-transition-enter {
    opacity: 0;
}
.phrase-transition-leave-to {
    opacity: 0;
}
</style>