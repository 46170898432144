<template>
    <v-app-bar app elevation="6" inverted-scroll>
        <!-- Burger menu -->
        <v-app-bar-nav-icon class="hidden-md-and-up" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-navigation-drawer v-model="drawer" absolute temporary class="fill-height" app>
            <!-- <v-list-item>
                <v-img
                    src="@/assets/logo_tiny.png"
                    max-width="8%"
                    max-height="100%"
                    contain
                    style="margin-left: 10px; margin-right: 10px"
                ></v-img>
            </v-list-item>
            <v-divider></v-divider>-->
            <v-list dense>
                <v-list-item
                    v-for="section in site_sections"
                    :key="section.id"
                    class="tab-button"
                    link
                    @click.stop="drawer = !drawer"
                    v-scroll-to="{el: '#' + section.id, offset: scroll_offset}"
                >
                    <v-list-item-content>
                        <v-list-item-title>{{ section.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <!-- Logo -->
        <v-img src="@/assets/logo_big.png" max-width="220px" contain @click="scrollToTop()"></v-img>
        <v-spacer></v-spacer>
        <!-- Botones Secciones -->
        <v-toolbar-items class="hidden-sm-and-down">
            <v-btn
                v-for="section in site_sections"
                :key="section.id"
                text
                class="tab-button"
                :class="`${isCurrentSection(section) ? 'font-weight-bold' : ''}`"
                v-scroll-to="{el: '#' + section.id, offset: scroll_offset}"
            >{{section.title}}</v-btn>
        </v-toolbar-items>
    </v-app-bar>
</template>

<script>
import Vue from "vue"
import VueScrollTo from "vue-scrollto"

Vue.use(VueScrollTo)

export default {
    data() {
        return {
            drawer: false,
            scroll_offset: -60 // AppBar with 'dense' prop is 48px tall
        }
    },
    props: {
        site_sections: Array
    },
    methods: {
        scrollToTop() {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
        },
        /** True if section is currently visible */
        isCurrentSection() {
            return false;
        }
    }
}
</script>
<style lang="scss" scoped>
.tab-button  {
    transition: color .3s ease;
    &:hover {
        color: $ilpa-red;
    }
}
</style>