<template>
  <v-app :dark="true">
    <ilpa-app-bar :site_sections="site_sections" />
    <v-content>
      <ilpa-slideshow />
      <ilpa-new-release-bambu v-if="false" />
      <ilpa-new-release-derecho-vital @open-video="onOpenVideo" @open-link="onOpenLink" />
      <ilpa-discography id="albums"  />
      <v-parallax dark
                  src="@/assets/img/slides/DSC_0180 teatro ilpa aire libre.jpg"
                  class="myparallax mt-2"
                  height="400"></v-parallax>
      <ilpa-videos id="videos" ref="videos" />
      <!-- <ilpa-contact /> -->
      <ilpa-photos />
      <!-- <div class="py-12">
                <ilpa-overview />
            </div>-->
      <div>
        <ilpa-social-networks id="social" />
      </div>
      <ilpa-next-events id="upcoming" />
      <ilpa-about id="us"
                  class="mt-1 mb-0" />
      <ilpa-footer />
    </v-content>
  </v-app>
</template>
<script>
import IlpaAppBar from "./components/IlpaAppBar"
import IlpaSlideshow from "./components/IlpaSlideshow/IlpaSlideshow"
import IlpaNewReleaseBambu from "./components/IlpaNewReleaseBambu"
import IlpaNewReleaseDerechoVital from "./components/IlpaNewReleaseDerechoVital"
import IlpaDiscography from "./components/IlpaDiscography"
import IlpaSocialNetworks from "./components/IlpaSocialNetworks"
import IlpaNextEvents from "./components/IlpaNextEvents"
import IlpaVideos from "./components/IlpaVideos"
import IlpaPhotos from "./components/IlpaPhotos"
import IlpaAbout from "./components/IlpaAbout"
import IlpaFooter from "./components/IlpaFooter/Footer"
// import IlpaOverview from "./components/IlpaOverview"

export default {
  name: "App",
  components: {
    IlpaAppBar,
    IlpaSlideshow,
    IlpaNewReleaseBambu,
    IlpaNewReleaseDerechoVital,
    // IlpaOverview,
    IlpaDiscography,
    IlpaSocialNetworks,
    IlpaNextEvents,
    IlpaVideos,
    IlpaPhotos,
    IlpaAbout,
    IlpaFooter
  },
  data: () => ({
    site_sections: [{
        title: "Discos",
        id: "albums"
      },
      {
        title: "Videos",
        id: "videos"
      },
      {
        title: "Está pasando",
        id: "social"
      },
      {
        title: "Eventos",
        id: "upcoming"
      },
      {
        title: "Nosotros",
        id: "us"
      }
    ]
  }),
  methods: {
    onOpenVideo(id) {
      this.$nextTick().then(() => {
        this.$refs.videos.play(id)
      })
    },
    // Open on new tab
    onOpenLink(link) {
      const a = document.createElement('a')
      a.href = link
      a.target = 'blank'
      a.click()
      a.remove()
    }
  }
}
</script>
<style scoped lang="scss"> 
body {
  overflow-x: hidden;
}
a {
    color: $ilpa-red;
}
::v-deep .v-icon.mdi {
  color: $ilpa-red;
}
</style>