<template>
  <v-container class="mt-12 mb-12">
    <div>
      <div class="display-1">Sobre Nosotros</div>
      <v-divider class="my-3"></v-divider>
      <v-img :width="`${imageWidth}`" contain class="float-right ml-10 mb-10"
             src="@/assets/img/ilpa-2023-curriculo.jpeg"></v-img>
      <div class="text-justify">
        <p class="body-2 text-justify">
          <span class="font-weight-bold">iLPAKAMANi</span> es una banda Chilena de música y folclor popular con más de 20 años de trayectoria y 4 discos grabados, que se ha presentado en conciertos en Chile y el Extranjero.
        </p>
        <p class="body-2 text-justify"><span class="font-weight-bold">iLPAKAMANi</span> es folclor actual y étnico de composición popular, que ha logrando un estilo y sonido propio de vitalidad constante, que parte de las raíces del mundo andino e indo de Latinoamérica.</p>
      </div>
      <div>
        <div class="title">Sus inicios</div>
        <p class="body-2 text-justify"><span class="font-weight-bold ">iLPAKAMANi</span> nace en 1992 Lengua Quechua (rayo poderoso), difundiendo como principal propósito la música andina y la integración de las artes de América.</p>
        <div class="title">En lo musical</div>
        <p class="body-2 text-justify">
          Desde sus inicios en 1992, <span class="font-weight-bold ">iLPAKAMANi</span> ha desarrollado un trabajo musical constante, que parte de las raíces del mundo andino, pero con rasgos de urbanidad, sonidos y composición popular, desde esa fuente nacen sus construcciones armónicas y sonoras.
          El paso de los años le han abierto a <span class="font-weight-bold ">iLPAKAMANi</span> nuevos horizontes y gustos musicales con sonidos distintos, melodías, ritmos y esbozos de bases ligadas al Rock entregándole una mixtura y un sonido étnico popular, indoamericano definido y característico de vitalidad constante.
        </p>
        <p class="body-2 text-justify">
          <span class="font-weight-bold  ">iLPAKAMANi</span> utiliza una gran variedad de instrumentos musicales de diversas zonas y orígenes. Los vientos aerófonos nativos más ancestrales: zampoñas en todas sus familias, jachaquenas, quenas, quenachos, tarkas, sicuras, moceños, trutrucas, pifilca, didgeridoo, además de saxofones. De las cuerdas de América latina ejecutan charango, cuatro Venezolano, tiple Colombiano, conjuntamente con guitarras electro acústicas, bajo eléctrico.
          Interpretan además instrumentos de percusión tales como Caja de rock, bombo, congas, bongo, kultrún, accesorios y percusiones latinas.
        </p>
      </div>
    </div>
    <v-row justify="center"
           class="px-3">
      <v-btn class="float-right mt-4"
             outlined
             :loading="loadingBook"
             @click.prevent="downloadPortfolio()">
        Descarga nuestro Book de trabajo
        <v-icon class="ml-3" secondary>mdi-file-pdf-box</v-icon>
      </v-btn>
    </v-row>
  </v-container>
</template>
<script>
export default {
  computed: {
    imageWidth(){
      return this.$vuetify.breakpoint.mdAndDown ? '100%' : '60%'
    }
  },
  data() {
    return {
      loadingBook: false
    }
  },
  methods: {
    downloadPortfolio() {
      this.loadingBook = true
      fetch(`BOOK_DE_TRABAJO_ILPAKAMANI_2019.pdf`)
        .then(resp => resp.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement("a")
          a.style.display = "none"
          a.href = url
          // the filename you want
          a.download = `Book-de-trabajo-Ilpakamani-${new Date().getFullYear()}.pdf`
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
          this.loadingBook = false
        })
        .catch(() => alert("oh no!"))
    }
  }
}
</script>
