<template>
  <div>
    <span :style="`font-size: ${Math.round(fontSize*0.4)}px`">Nos sigue el</span>
    <span :style="`font-family: 'Fredericka the Great', cursive !important; font-weight: 300; font-size: ${fontSize}px; margin-left: ${Math.round(fontSize*0.15)}px`">
      pueblo mestizo y originario
    </span>
  </div>
</template>
<script>
export default {
  name: 'PhraseComponent',
  computed: {
    fontSize() {
      const step = 17
      const maxSize = 110
      const fontSizes = {
        xs: maxSize - step * 4,
        sm: maxSize - step * 3,
        md: maxSize - step * 2,
        lg: maxSize - step,
        xl: maxSize
      }
      return fontSizes[this.$vuetify.breakpoint.name]
    }
  }
}
</script>