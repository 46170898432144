<template>
  <div class="pb-8 mb-12">
    <v-container>
      <base-title>En Redes Sociales</base-title>
      <v-row class="px-6">
        <!-- Facebook -->
        <v-col sm="12"
          md="6"
          lg="4"
          id="fb-iframe"
          class="fill-height"
          align="center"
          ref="fbcontainer">
          <div class="d-flex justify-center mb-4">
            <v-icon large
              class="mr-6"
              style="width: 0">mdi-facebook</v-icon>
            <div class="text-uppercase display-1">Facebook</div>
          </div>
          <div class="social-container">
            <iframe :src="`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Filpakamanibanda%2F&tabs=timeline&width=${social_iframe.width}&height=${social_iframe.height}&small_header=false&adapt_container_width=false&hide_cover=false&show_facepile=true&appId`"
              :width="social_iframe.width"
              :height="social_iframe.height"
              frameborder="0"
              allowtransparency="true"
              allow="encrypted-media"
              :key="social_iframe.width"></iframe>
          </div>
        </v-col>
        <!-- Instagram -->
        <v-col sm="12"
          md="6"
          lg="4"
          id="ig-iframe"
          class="fill-height"
          align="center">
          <div class="d-flex justify-center mb-4">
            <v-icon large
              class="mr-6"
              style="width: 0">mdi-instagram</v-icon>
            <div class="text-uppercase display-1">Instagram</div>
          </div>
          <div class="social-container">
            
            <blockquote class="instagram-media ma-0"
              v-if="instagramReady"
              data-instgrm-captioned
              :data-instgrm-permalink="`https://www.instagram.com/p/${igPermalink}/?utm_source=ig_embed&amp;utm_campaign=loading`"
              data-instgrm-version="13"
              style=" background:#FFF; border:0; border-radius:3px; box-shadow:0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15); margin: 1px; max-width:500px; min-width:326px; padding:0;;"
              :style="`height: ${social_iframe.height}px; width: ${social_iframe.width}px`">
              <div style="padding:16px;"> <a :href="`https://www.instagram.com/p/${igPermalink}/?utm_source=ig_embed&amp;utm_campaign=loading`"
                  style=" background:#FFFFFF; line-height:0; padding:0 0; text-align:center; text-decoration:none; width:100%;"
                  target="_blank">
                  <div style=" display: flex; flex-direction: row; align-items: center;">
                    <div style="background-color: #F4F4F4; border-radius: 50%; flex-grow: 0; height: 40px; margin-right: 14px; width: 40px;"></div>
                    <div style="display: flex; flex-direction: column; flex-grow: 1; justify-content: center;">
                      <div style=" background-color: #F4F4F4; border-radius: 4px; flex-grow: 0; height: 14px; margin-bottom: 6px; width: 100px;"></div>
                      <div style=" background-color: #F4F4F4; border-radius: 4px; flex-grow: 0; height: 14px; width: 60px;"></div>
                    </div>
                  </div>
                  <div style="padding: 19% 0;"></div>
                  <div style="display:block; height:50px; margin:0 auto 12px; width:50px;">
                    <v-icon x-large
                      style="color: #262626">mdi-instagram</v-icon>
                  </div>
                  <div style="padding-top: 8px;">
                    <div style=" color:#3897f0; font-family:Arial,sans-serif; font-size:14px; font-style:normal; font-weight:550; line-height:18px;">
                      Ver en Instagram</div>
                  </div>
                  <div style="padding: 12.5% 0;"></div>
                  <div style="display: flex; flex-direction: row; margin-bottom: 14px; align-items: center;">
                    <div>
                      <div style="background-color: #F4F4F4; border-radius: 50%; height: 12.5px; width: 12.5px; transform: translateX(0px) translateY(7px);"></div>
                      <div style="background-color: #F4F4F4; height: 12.5px; transform: rotate(-45deg) translateX(3px) translateY(1px); width: 12.5px; flex-grow: 0; margin-right: 14px; margin-left: 2px;"></div>
                      <div style="background-color: #F4F4F4; border-radius: 50%; height: 12.5px; width: 12.5px; transform: translateX(9px) translateY(-18px);"></div>
                    </div>
                    <div style="margin-left: 8px;">
                      <div style=" background-color: #F4F4F4; border-radius: 50%; flex-grow: 0; height: 20px; width: 20px;"></div>
                      <div style=" width: 0; height: 0; border-top: 2px solid transparent; border-left: 6px solid #f4f4f4; border-bottom: 2px solid transparent; transform: translateX(16px) translateY(-4px) rotate(30deg)"></div>
                    </div>
                    <div style="margin-left: auto;">
                      <div style=" width: 0px; border-top: 8px solid #F4F4F4; border-right: 8px solid transparent; transform: translateY(16px);"></div>
                      <div style=" background-color: #F4F4F4; flex-grow: 0; height: 12px; width: 16px; transform: translateY(-4px);"></div>
                      <div style=" width: 0; height: 0; border-top: 8px solid #F4F4F4; border-left: 8px solid transparent; transform: translateY(-4px) translateX(8px);"></div>
                    </div>
                  </div>
                  <div style="display: flex; flex-direction: column; flex-grow: 1; justify-content: center; margin-bottom: 24px;">
                    <div style=" background-color: #F4F4F4; border-radius: 4px; flex-grow: 0; height: 14px; margin-bottom: 6px; width: 224px;"></div>
                    <div style=" background-color: #F4F4F4; border-radius: 4px; flex-grow: 0; height: 14px; width: 144px;"></div>
                  </div>
                </a>
                <p style=" color:#c9c8cd; font-family:Arial,sans-serif; font-size:14px; line-height:17px; margin-bottom:0; margin-top:8px; overflow:hidden; padding:8px 0 7px; text-align:center; text-overflow:ellipsis; white-space:nowrap;"><a
                    href="https://www.instagram.com/p/COqEeYtJfwz/?utm_source=ig_embed&amp;utm_campaign=loading"
                    style=" color:#c9c8cd; font-family:Arial,sans-serif; font-size:14px; font-style:normal; font-weight:normal; line-height:17px; text-decoration:none;"
                    target="_blank">Post compartido por ilpakamani (@ilpakamani)</a></p>
              </div>
            </blockquote>
          </div>
        </v-col>
        <!-- Twitter -->
        <v-col sm="12"
          md="6"
          lg="4"
          id="tw-iframe"
          align="center"
          class="fill-height">
          <div class="d-flex justify-center mb-4">
            <v-icon large
              class="mr-6"
              style="width: 0">mdi-twitter</v-icon>
            <div class="text-uppercase display-1">Twitter</div>
          </div>
          <div class="social-container ma-0"
            id="twitter-container"
            :style="`max-height: ${social_iframe.height}px`">
            <a id="twitter-placeholder"
              class="twitter-timeline"
              data-lang="es"
              data-link-color="#E81C4F"
              :data-height="social_iframe.height"
              :data-width="social_iframe.width"
              href="https://twitter.com/ilpakamanibanda?ref_src=twsrc%5Etfw">
              Tweets by
              ilpakamanibanda
            </a>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      social_iframe: {
        height: 0,
        width: 0
      },
      instagramReady: false,
      igPermalink: 'CqqLpcjgos5'
    }
  },
  mounted() {
    this.social_iframe = {
      height: Math.max(Math.round(window.innerHeight * 0.8), 500),
      width: Math.min(Math.round(this.$refs.fbcontainer.clientWidth * 0.9), 500)
    }
    // const vm = this
    ;
    (function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0],
        t = window.twttr || {}

      if (d.getElementById(id)) {
        // t.events.bind('loaded', (event) => {
        //   event.widgets.forEach((widget) => {
        //     widget.style.marginBottom = "-7px !important"
        //   })
        // })
        return t.widgets.load(d.getElementById('twitter-placeholder'))
      }
      js = d.createElement(s)
      js.id = id
      js.src = "https://platform.twitter.com/widgets.js"
      fjs.parentNode.insertBefore(js, fjs)

      t._e = []
      t.ready = function(f) {
        t._e.push(f)
      }
      return t
    })(document, "script", "twitter-wjs")


    // Add instagram embed plugin
    const vm = this
    this.$nextTick().then(() => {
      var js = document.createElement('script')
      js.id = 'instagram-embed'
      js.src = '//www.instagram.com/embed.js'
      var fjs = document.getElementsByTagName('script')[0]
      fjs.parentNode.insertBefore(js, fjs)
      vm.instagramReady = true
    })

    // var request = new XMLHttpRequest();
    // request.open('GET',
    //   'https://api.instagram.com/v1/users/self/media/recent/?access_token=IGQVJWWXJoTUFDaFBNOWVwZAzZAwWGEwU0l6QTNzQUE5QzhtWEJCWlo3d08xQ2ZAXeTllcTlwUmp1NUZAkekU2bmp1cHN3WVFRNThCVHF6U3lXbEFRMnNwQXIwclVyaGNDU25WVTdIUURrT1Nrc3NoU1dpZAAZDZD&count=8',
    //   true);
    // request.onload = function() {
    //   if (request.status >= 200 && request.status < 400) {
    //     // Success!
    //     var data = JSON.parse(request.responseText);
    //     for (var i = 0; i < data.data.length; i++) {
    //       var container = document.getElementById('insta-feed');
    //       var imgURL = data.data[i].images.standard_resolution.url;
    //       var div = document.createElement('div');
    //       div.setAttribute('class', 'instapic');
    //       container.appendChild(div);
    //       var img = document.createElement('img');
    //       img.setAttribute('src', imgURL)
    //       div.appendChild(img);
    //     }
    //     // console.log(data);
    //   }
    // };
    // const vm = this
    // request.onerror = function() {
    //   //There was a connection error of some sort
    //   // console.log("ERROOOOOOOOORR", e)
    //   vm.instagramFailed = true
    // };
    // request.send();

  },
  methods: {
    // debug(msg = 'DEBUUG') {
    //     // console.log(`%c ${msg}`, "background-color: black; color: #00ff00")
    // }
  }
}
</script>
<style scoped>
.social-container {
  border: solid 1px white;
  width: fit-content;
}

iframe,
.social-container {
  border-radius: 8px;
}

iframe {
  padding: 0;
  margin-bottom: -6px !important;
}
</style>