<template>
  <v-container class="d-flex container pa-0" fluid>
    <!-- <video playsinline autoplay muted loop :poster="require('@/assets/img/referencia.jpg')" id="bgvideo" ref="releaseVideo">
            <source :src="require('@/assets/video/derechovital_b500k.webm')" type="video/webm">
        </video> -->
    <v-img :src="require('@/assets/img/caratula_bambu_blurred2.jpeg')"
      cover height="100vh" ></v-img>
    <div class="container__content white--text d-flex justify-center align-center h-100 w-100">
      <div class="d-flex justify-center align-center">
        <div class="text-center">
          <v-img max-width="500px" :src="require('@/assets/img/bambu-logo.png')"></v-img>
          <div class="container__content--subtitle">Nuevo Disco</div>
        </div>
          <v-img max-width="500px" :src="require('@/assets/img/caratula_bambu.jpeg')"></v-img>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'IlpaNewReleaseBambu',
  mounted() {
    this.$nextTick().then(() => {
      this.$refs.releaseVideo.playbackRate = 0.8;
    })
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  &__bg {
  }
  &__content {
    position: absolute;
    // width: clamp(300px, 50%, 500px);
    font-family: $font-accent;
    font-size: 2em;
  }
}
video {
  opacity: 0.4;
  margin-left: 50%;
  transform: translateX(-50%);
  width: clamp(700px, 100%, 100%);

}
</style>