<template>
  <v-card flat
    tile
    dark
    class="pb-10 grey darken-4">
    <v-container>
      <base-title>Fotos históricas</base-title>
      <v-row>
        <v-col v-for="(album, index) in albums"
          :key="`album_${index}`"
          cols="12"
          md="6">
          <album-card :items="album.items"
            :title="album.name"
            @click="onSelectAlbum(index, $event)"></album-card>
        </v-col>
        <v-col cols="12"
          md="6"
          align-self="center">
          <!-- <v-row justify="center">
            <v-col v-for="(image, index) in images" :key="`image_${index}`" >
              <v-img :src="require(`@/assets/img/gallery/${image}`)" width="200"
                @click="overlayImageIndex = index"
                class="gallery-image" />
            </v-col>
          </v-row> -->
        </v-col>
      </v-row>
    </v-container>
    <base-gallery
      :first-picture="firstPictureIndex"
      :items="selectedAlbumIndex !== null && albums && albums[selectedAlbumIndex] ? albums[selectedAlbumIndex].items : []"
      :thumbs="selectedAlbumIndex !== null && albums && albums[selectedAlbumIndex] ? albums[selectedAlbumIndex].thumbs : []"
      :title="selectedAlbumIndex !== null && albums ? albums[selectedAlbumIndex].name : null"
      :show="selectedAlbumIndex !== null"
      @close="selectedAlbumIndex = null"></base-gallery>
  </v-card>
</template>
<script>
import AlbumCard from '@/components/AlbumCard'

export default {
  components: { AlbumCard },

  data() {
    return {
      albums: [
      {
        name: 'Presente',
        items: require.context('@/assets/img/slides', false, /\.(png|jpe?g)$/)
        .keys().map(path => require(`@/assets/img/slides${path.substring(1)}`)).rotate(10),
        thumbs: require.context('@/assets/img/slides/thumb', false, /\.(png|jpe?g)$/)
        .keys().map(path => require(`@/assets/img/slides/thumb${path.substring(1)}`)).rotate(10)
      },
      {
        name: 'Retrospectiva',
        items: require.context('@/assets/img/gallery', false, /\.(png|jpe?g)$/)
        .keys().map(path => require(`@/assets/img/gallery${path.substring(1)}`)).rotate(8),
        thumbs: require.context('@/assets/img/gallery/thumb', false, /\.(png|jpe?g)$/)
        .keys().map(path => require(`@/assets/img/gallery/thumb${path.substring(1)}`)).rotate(8),
      }],
      firstPictureIndex: 0,
      selectedAlbumIndex: null
    }
  },
  computed: {
    // overlayImage() {
    //   return this.overlayImageIndex !== null ? this.images[this.overlayImageIndex] : null
    // }
  },
  methods: {
    onSelectAlbum(albumIndex, photoIndex) {
      this.firstPictureIndex = photoIndex
      this.selectedAlbumIndex = albumIndex
    }
    // hideOverlay() {
    //   this.overlayImageIndex = null
    // },
    // nextImage() {
    //   this.overlayImageIndex = (this.overlayImageIndex + 1) % this.images.length
    // }
  }
}
</script>
<style scoped
  lang="scss">
.overlay {
  opacity: 1;
  transition: opacity .3s ease;
  position: fixed;
  z-index: 10;
  background-color: rgba(33, 33, 33, 0.46);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  &__image {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    // height: 80%;
    max-height: 80%;
    object-fit: cover;
    min-width: 400px;
    border-radius: 4px;
  }

  &--hide {
    pointer-events: none;
    opacity: 0;
  }
}

.gallery-image {
  border-radius: 3px;
  margin-left: auto;
  margin-right: auto;
  transition: filter .3s ease, transform .3s ease;
  cursor: pointer;

  &:hover {
    filter: brightness(0.8);
    transform: scale(1.02);
  }
}
</style>