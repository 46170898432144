import { render, staticRenderFns } from "./BaseGallery.vue?vue&type=template&id=2d046eea&scoped=true&"
import script from "./BaseGallery.vue?vue&type=script&lang=js&"
export * from "./BaseGallery.vue?vue&type=script&lang=js&"
import style0 from "./BaseGallery.vue?vue&type=style&index=0&lang=css&"
import style1 from "./BaseGallery.vue?vue&type=style&index=1&id=2d046eea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d046eea",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VChip,VIcon})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})
