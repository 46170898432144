import Vue from 'vue'

// Register all components
var requireComponent = require.context('./', true, /.vue$/)
requireComponent.keys().forEach(function (fileName) {
  var baseComponentConfig = requireComponent(fileName)
  baseComponentConfig = baseComponentConfig.default || baseComponentConfig
  if (baseComponentConfig.name) {
    Vue.component(baseComponentConfig.name, baseComponentConfig)
  }
})