<template>
    <v-container class="mb-12">
        <base-title>Discografía</base-title>
        <v-row justify="center" :class="`${$vuetify.breakpoint.mdAndDown ? 'mx-5' : ''}`">
            <v-col v-for="(album, index) in albums" :key="`album_${index}`"
                xs="10"
                sm="8"
                md="6"
                lg="4"
                class="pa-10 align-content-space-between">
                <v-hover v-slot:default="{ hover }">
                    <div class="d-flex flex-wrap justify-center align-content-space-between" style="height: 100%;">
                        <!-- <v-card :elevation="hover ? 10 : 0">
                            <v-img
                                :src="require('@/assets/img/' + album.cover)"
                                class="album-cover"
                                @mouseover="selected_album=`album_${index}`"
                                @mouseleave="selected_album=''"
                            >
                            </v-img>
                        </v-card>
                        <div
                            :class="`text-center title font-weight-bold text-uppercase mt-4 album-title ${hover ? 'hover': ''}`"
                        >
                            {{album.name}}
                            -
                            {{album.year}}
                        </div>-->

                        <v-card :elevation="!hover && albumIsToggable(album) ? 6 : hover && albumIsToggable(album) ? 10 : 1" dark>
                            <v-img
                                :src="require('@/assets/img/' + album.cover)"
                                :class="`album-cover ${toggledAlbums.includes(index) ? 'toggled' : ''} 
                                    ${albumIsToggable(album) ? 'hoverable' : ''} white--text align-end`"
                                position="top center"
                                @click="toggleAlbum(index)"
                            >
                                <v-fade-transition>
                                    <v-card-title
                                        v-if="toggledAlbums.includes(index)"
                                        class="text-uppercase"
                                        style="background: linear-gradient(0deg, #000000ff 0%, #00000000 90%);"
                                    >{{album.name}}</v-card-title>
                                </v-fade-transition>
                            </v-img>
                            <v-expand-transition>
                                <div v-if="toggledAlbums.includes(index)">
                                    <!-- <v-card-subtitle>Año {{album.year}}</v-card-subtitle> -->
                                    <v-card-text
                                        ref="spotifyPlayerContainer"
                                        class="pa-0 align-bottom"
                                    >
                                        <v-list v-if="!album.spotifyId && album.songs" dense>
                                            <v-list-item
                                                v-for="(song, index) in album.songs"
                                                :key="`albumsong_${album.name}_${song}`"
                                                class="text-capitalize"
                                            >{{index + 1}} - {{song}}</v-list-item>
                                        </v-list>
                                        <v-skeleton-loader
                                            v-if="album.spotifyId && !embedsLoaded.includes(album.spotifyId)"
                                            :loading="true"
                                            type="list-item-avatar-three-line, list-item@5"
                                            dark
                                        ></v-skeleton-loader>
                                        <iframe
                                            v-if="album.spotifyId"
                                            :src="`https://open.spotify.com/embed/album/${album.spotifyId}`"
                                            :height="`${embedsLoaded.includes(album.spotifyId) ? '380' : '0'}`"
                                            frameborder="0"
                                            allowtransparency="true"
                                            allow="encrypted-media"
                                            style="width: 100%; border-radius: 4px; margin-bottom: -10px"
                                            @load="embedsLoaded.push(album.spotifyId)"
                                        ></iframe>
                                    </v-card-text>
                                </div>
                            </v-expand-transition>
                        </v-card>
                        <v-fade-transition>
                            <div
                                @click="toggleAlbum(index)"
                                v-if="!toggledAlbums.includes(index)"
                                :class="`text-center title font-weight-bold text-uppercase mt-4 album-title ${albumIsToggable(album) ? 'hoverable' : ''} ${hover ? 'hover': ''}`"
                            >
                                {{album.name}}
                                <!-- - -->
                                <!-- {{album.year}} -->
                            </div>
                        </v-fade-transition>
                    </div>
                </v-hover>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            albums: [
            {
                name: "Bambú",
                cover: 'caratula_bambu.jpeg',
                songs: [
                    "Siete mundos",
                    "Nn",
                    "Por un dolor",
                    "Jutiri",
                    "Quellén",
                    "Valicha",
                    "Chopan",
                    "Dimensiones",
                ]
            },
                {
                    name: "Nuevos colores",
                    cover: "caratula_nuevos_colores.jpg",
                    spotifyId: "6mDWzGULOBBz48C4PonEqX",
                },
                {
                    name: "Hoy estamos aquí",
                    cover: "caratula_hoy_estamos_aqui.jpg",
                    spotifyId: "0dtJOuQNgulL3BkqpVSWBm"
                },
                {
                    name: "Más al Sur",
                    cover: "caratula_mas al sur_2.jpg",
                },
                {
                    name: "El Canto de la Calle (colaboración)",
                    cover: "caratula_elcantodelacalle.jpg",
                },
            ],
            toggledAlbums: [],
            embedsLoaded: []
        }
    },
    methods: {
        toggleAlbum(index) {
            if (!this.albumIsToggable(this.albums[index])){
                return
            }
            if (this.toggledAlbums.includes(index)) {
                if (this.albums[index].spotifyId) {
                    this.embedsLoaded.pop(this.albums[index].spotifyId)
                }
                this.toggledAlbums = this.toggledAlbums.filter(val => {
                    return val !== index
                })
            } else this.toggledAlbums.push(index)
        },
        albumIsToggable(album){
            return album.spotifyId || album.songs
        }
    }
}
</script>

<style lang="scss" scoped>
.album-cover {
    max-height: 90vh;
    transition: max-height 0.4s ease, opacity 0.2s ease;
    /* transition: opacity 0.2s ease, max-height 0.3s ease; */
    &.hoverable {
        transition: opacity 0.3s ease;
        cursor: pointer;
        &:hover {
            opacity: 0.7;
        }
    }
}
.album-cover.toggled {
    max-height: 200px;
}

/* 
.album-cover-icon {
    color: rgba(0, 0, 0, 0.8) !important;
    opacity: 1 !important;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) scale(1.5);
    transition: opacity 0.2s ease, transform 0.2s ease;
}

.album-cover-icon-gone {
    opacity: 0 !important;
    transform: translate(-50%, 50%) scale(1.5);
} */

.album-title {
    &.hoverable {
        cursor: pointer;
        transition: transform 0.2s ease;
        &.hover {
            transform: scale(1.1);
        }
    }
}
</style>
<style>
.myparallax .v-parallax__image {
    filter: brightness(0.5);
}
</style>