<template>
  <div class="gallery__wrapper"
    :class="`${!show ? 'gallery__wrapper--hide' : ''}`">
    <!-- @click.prevent.stop="$emit('close')" -->
    <div class="gallery__inner" v-if="items && items.length && show">
      <div class="mb-12 mt-n12"
        :class="`${$vuetify.breakpoint.mdAndDown ? 'display-2' : 'display-3'}`" v-if="title">{{title}}</div>
      <div class="gallery__flex"
        v-click-outside="onClose">
        <div class="gallery__fading-images">
          <img class="gallery__image top"
            ref="top"
            :key="`top_${key}`"
            :src="toggle ? currentItem : lastItem "
            alt="current item"
            loading="lazy"
            :class="`gallery__image${top ? '--show' : '--hide'}`"
            @load="onChange(toggle && $event)">
          <img class="gallery__image bottom"
            ref="bottom"
            :key="`bottom_${key}`"
            loading="lazy"
            :src="!toggle ? currentItem : lastItem "
            alt="current item"
            :class="`gallery__image${bottom ? '--show' : '--hide'}`"
            @load="onChange(!toggle && $event)">
          <div class="arrows">
            <v-btn class="arrow__left"
              icon
              x-large
              @click="prev">
              <v-icon color="white" x-large>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn class="arrow__right"
              icon
              x-large
              @click="next">
              <v-icon color="white" x-large>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
          <v-chip class="chip" large>
            <div class="chip__text">{{ currentItemIndex + 1 }} de {{ items.length }}</div>
          </v-chip>
        </div>
      </div>
      <div class="thumbs"
        :style="`width: ${width}`">
        <div class="thumbs__wrapper"
          v-for="(thumbIndex, index) in centeredThumbsIndexes"
          :key="`slide_${index}`">
          <img class="thumbs__image"
            loading="lazy"
            :src="thumbs[thumbIndex]"
            :alt="`image thumb ${thumbIndex}`"
            @click="currentItemIndex = thumbIndex">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BaseGallery',
  props: {
    items: {
      type: Array,
      required: true
    },
    thumbs: {
      type: Array,
      default: () => []
    },
    autoScroll: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null,
    },
    firstPicture: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      currentItemIndex: 0,
      toggle: false,
      lastIndex: 0,
      width: "100%",
      key: Date.now()
    }
  },
  watch: {
    firstPicture: {
      immediate: true,
      handler(newVal) {
        this.currentItemIndex = newVal
      }
    },
    currentItemIndex(newVal, oldVal) {
      this.toggle = !this.toggle
      this.lastIndex = oldVal
    },
    show(newVal) {
      this.$nextTick().then(() => {
        const body = document.getElementsByTagName('body')
        for (var i = 0; i < body.length; i+=1) {
          if (newVal)
            body[i].classList.add('noscroll')
          else
            body[i].classList.remove('noscroll')
        }
      })
    }
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    currentItem() {
      return this.items[this.currentItemIndex]
    },
    lastItem() {
      return this.items[this.lastIndex]
    },
    centeredThumbsIndexes() {
      return [...[...Array(this.items.length * 2 + 1).keys()].map(i => (i + this.currentItemIndex) % this.items.length)]
    },
    top() {
      return this.toggle
    },
    bottom() {
      return !this.toggle
    }
  },
  methods: {
    next() {
      this.currentItemIndex = (this.currentItemIndex + 1) % this.items.length
    },
    prev() {
      this.currentItemIndex = (this.currentItemIndex + this.items.length - 1) % this.items.length
    },
    onChange(evt) {
      if (!evt) return
      const { target: { clientWidth: width } } = evt
      this.$nextTick().then(() => {
        this.width = `${width}px`
      })
    },
    onResize() {
      this.$nextTick().then(() => {
        this.width = this.$refs[this.toggle ? 'top' : 'bottom'].clientWidth + "px"
      })
    },
    onClose(evt) {
      const classes = evt.target.classList
      var found = false
      for (var i = 0; i < classes.length; i += 1) {
        if (classes[i].startsWith('gallery__')) {
          found = true;
          break;
        }
      }
      if (!found)
        return
      if (this.show)
        this.$emit('close')
    }
  }
}
</script>
<style>
.noscroll {
  overflow: hidden;
}
</style>
<style lang="scss"
  scoped>
$thumbs-size: 15%;
$min-width: 300px;


.title {
   font-family: "Exo", sans-serif !important;
}
.gallery {

  // Overlay
  &__wrapper {
    z-index: 10;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    margin: 0;
    background-color: rgba(black, 0.9);
    transition: opacity 0.3s ease;

    display: flex;
    align-items: center;
    justify-content: center;

    &--hide {
      pointer-events: none;
      opacity: 0;
    }
  }

  &__inner {
    text-align: center;
    width: 80%;
    max-height: 80%;
    min-width: $min-width;
  }

  &__flex {
    justify-content: center;
    align-items: center;
    display: inline-flex;

    & .arrows {
      position: absolute;
      min-width: $min-width;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      display: flex;
      /* z-index: 12; */
      justify-content: space-between;

      ::v-deep .v-btn i {
        font-size: 70px !important;

        text-shadow: 0px 0px 6px black;
      }
    }
    .chip {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 10px;
      &__text {
        font-size: 1.2em; 
      }
      ::v-deep &.v-chip {
        background-color: rgba(black, 0.6);
      }
    }
  }

  &__fading-images {
    // width: 80vw;
    min-width: $min-width;
    height: 80%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }

  &__image {
    transition: opacity .3s ease;
    max-width: 100%;
    min-width: $min-width;
    object-fit: cover;
    border-radius: 5px;
    max-height: 60vh;
    margin-left: auto;
    margin-right: auto;
    &.bottom {
      // margin-bottom: -100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    &--show {
      opacity: 1;
    }

    &--hide {
      opacity: 0;
    }
  }

}



.thumbs {
  width: 100%;
  min-width: $min-width;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;

  margin-top: 10px;
  display: inline-flex;
  justify-content: center;

  &__wrapper {
    transition: all .3s ease;

    margin: 0 5px;
    flex: none;
    width: $thumbs-size;
    padding-bottom: $thumbs-size;
    min-width: 100px;
    min-height: 100px;
    line-height: 0;
    height: 0;

    position: relative;
    overflow: hidden;
    border-radius: 5px;
  }

  &__image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 0;
    max-height: 100%;
    transform: translateX(-50%);

    overflow: hidden;
    object-fit: cover;
    cursor: pointer;
    transition: filter .3s ease, transform .3s ease;

    &:hover {
      filter: brightness(.8);
      transform: translateX(-50%) scale(1.02);
    }

    // min-width: 100px;
    // max-width: 200px;
    // width: 10%;
    border-radius: 5px;
  }

  position: relative;

  &:after {
    right: 0;
    background: linear-gradient(90deg, transparent 0%, rgba(black, .4) 50%, rgba(black, .7) 100%);
  }

  &:before {
    left: 0;
    background: linear-gradient(270deg, transparent 0%, rgba(black, .4) 50%, rgba(black, .7) 100%);
  }

  &:before,
  &:after {
    content: " ";
    position: absolute;
    z-index: 11;
    height: 100%;
    width: 120px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
}
</style>