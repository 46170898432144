<template>
  <v-container fluid class="pa-0 container">
    <v-img class="container__img white"
      cover
      height="100vh"
           :src="require('@/assets/' + bgImage)">
    </v-img>
    <v-container class="container__content" >
      <base-title ref="container__title">Próximos Eventos</base-title>
      <v-row justify="center" :style="`height: calc(100vh - ${titleHeightCss}); overflow-y: scroll;`">
        <v-col md="10"
               sm="12">
          <v-list three-line
                  subheader
                  flat
                  color="transparent"
                  class="overflow-y-auto overflow-x-auto">
            <div v-for="(event, index) in sortedEvents"
                 :key="`event_${event.date}_${index}`">
                 <v-list-item v-if="isFirstExpiredEvent(index)">
                   <v-list-item-content class="display-1 text-center ml-auto mr-auto">
                     <div class="text-center">Pasados</div>
                   </v-list-item-content>
                 </v-list-item>
              <v-list-item class="my-4" :class="eventExpired(event.date) ? 'list-item--expired' : ''">
                <v-list-item-avatar tile
                                    :size="`${$vuetify.breakpoint.mdAndDown ? '60': '100'}`"
                                    style="margin-top: auto; margin-bottom: auto">
                  <v-img v-if="event.flyer"
                         :src="require('@/assets/img/flyers/' + event.flyerThumb)"
                         :class="`${eventExpired(event.date) ? 'expired' :''} avatarimg`"
                         @click="overlay = event.flyer"></v-img>
                  <div v-else>
                    <v-icon>mdi-image-frame</v-icon>
                  </div>
                </v-list-item-avatar>
                <div :class="`${$vuetify.breakpoint.mdAndDown ? 'mr-2' : 'mx-10'} ${eventExpired(event.date) ? 'text--secondary' : ''}`">
                  <v-list-item-title>
                    <div class="display-1 text-center">{{ humanizeDate(event.date).day }}</div>
                    <div class="body-1 text-center text-uppercase">{{ humanizeDate(event.date).monthYear }}</div>
                  </v-list-item-title>
                </div>
                <v-list-item-content class="text-right">
                  <v-list-item-title :class="`${$vuetify.breakpoint.mdAndDown ? 'body-1' : 'title'} text-uppercase font-weight-bold ${eventExpired(event.date) ? 'text--secondary' : ''}`"
                                     style="white-space: pre-wrap">
                    <span v-if="event.name">{{event.name}}&nbsp;-&nbsp;</span>
                    <span>{{event.place.city}}</span>
                  </v-list-item-title>
                  <a @click="searchLocationInMaps(event.place)">
                    <v-list-item-subtitle>
                      <v-icon>mdi-map-marker</v-icon>
                      {{event.place.name}}: {{event.place.address}}
                    </v-list-item-subtitle>
                  </a>
                </v-list-item-content>
              </v-list-item>
              <v-divider v-if="index < events.length -1  "></v-divider>
            </div>
          </v-list>
        </v-col>
      </v-row>
      <v-dialog v-model="showOverlay"
        fullscreen
        content-class="flyer-dialog text-center elevation-0 h-100 w-100"
      >
        <!-- <div> -->
          <!-- <v-btn @click="overlay=false" class="float-right">
                    Cerrar
                    <v-icon>mdi-close</v-icon>
                </v-btn>-->
                <div class="h-100 w-100 d-flex flex-wrap justify-center align-center">
                  
          <img @click="overlayZoom =! overlayZoom"
                 v-if="showOverlay"
                 :src="require('@/assets/img/flyers/' + overlay)"
                 class="flyer"
                 :style="`${!overlayZoom ? 'max-width: 60vw;' : 'width: 90vw'}`"
                 v-click-outside="onCloseOverlay"
                 loading="lazy"
                 >
         <v-btn @click="showOverlay=false"
                 color="white"
                 fab
                 large
                 elevation="10"
                 style="position: fixed; top: 0; right: 0; margin: 2rem"
                 >
            <v-icon color="black">mdi-close</v-icon>
          </v-btn>
                </div>
        <!-- </div> -->
      </v-dialog>
    </v-container>
  </v-container>
</template>
<script>
import events from '@/assets/data/next-events'

export default {
  data() {
    return {
      bgImage: "img/slides/DSC_0180 teatro 4.jpg",
      overlay: null,
      overlayZoom: true,
      events,
      titleHeightCss: 0,
    }
  },
  mounted() {
    this.$nextTick().then(() => {
      const el = this.$refs.container__title.$el 
      const clientHeight = `${el.clientHeight}px`
      const { marginTop, marginBottom } = el.currentStyle || window.getComputedStyle(el)
      this.titleHeightCss = `calc(${[clientHeight, marginTop, marginBottom].join(' + ')})` 
    })
  },
  methods: {
    humanizeDate(rawdate) {
      const [day, month, year] = rawdate.split("/")
      const months = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
      ]
      const humanMonth = months[month - 1].substring(0, 3)
      return { day, monthYear: `${humanMonth} ${year}` }
    },
    // place(event) {
    //   return this.places[event.place]
    // },
    searchLocationInMaps(location) {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${location.name} ${location.address}, ${location.city}`,
        "_blank"
      )
    },
    eventExpired(eventDate) {
      const now = new Date()
      const parsedDate = this.dateStringToDate(eventDate)
      // const [day, month, year] = eventDate.split("/")
      // const parsedDate = new Date(`${year}-${month}-${day}`)
      return now > parsedDate
    },
    onCloseOverlay() {
      this.overlay = false
    },
    isFirstExpiredEvent(sortedEventIndex) {
      const thisEvent = this.sortedEvents[sortedEventIndex]
      const prevEvent = sortedEventIndex > 0 ? this.sortedEvents[sortedEventIndex - 1] : null
      return prevEvent ? !this.eventExpired(prevEvent.date) && this.eventExpired(thisEvent.date)
        : this.eventExpired(thisEvent.date)
    },
    dateStringToDate(dateString) {
      const [day, month, year] = dateString.split('/')
      return new Date(`${year}-${month}-${day}`)
    }
  },
  computed: {
    showOverlay: {
      get: function() {
        return Boolean(this.overlay)
      },
      set: function(newVal) {
        this.overlay = newVal
      }
    },
    // Sorted by date, newest first
    sortedEvents() {
      return [...this.events].sort((e1, e2) => this.dateStringToDate(e1.date) < this.dateStringToDate(e2.date))
    }
  },
  watch: {
    overlay(newval) {
      if (newval) {
        this.overlayZoom = false
      }
    }
  }
}
</script>
<style lang="scss">
.container {
  position: relative;
  &__img { 
    position: absolute;
  }

}
.avatarimg {
  opacity: 1;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.avatarimg:hover {
  opacity: 0.7;
  transform: scale(1.1);
}

.container__img>.v-image__image {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  opacity: 0.5;
  filter: grayscale(90%) brightness(2);
}

.container__img>div {
  background-color: #ffffff55;
}

.expired {
  filter: grayscale(100%);
}
.list-item--expired {
  opacity: 0.7;
  color: blue;
}
.container__content {
  color: black !important;
  .theme--dark {
    // ::v-deep &.v-application {
      // & .text--secondary {
        div, span {

        color: black !important;
        }
        i {
          color: $ilpa-red;
        }
      // }
    // }
  }
}

.flyer-dialog {
  background-color: rgba(black, 70%);
}
</style>
