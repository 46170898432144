<template>
  <div>
    <div style="font-size:  2em"
      class="text-center font-weight-bold mb-12">{{title}}</div>
    <v-hover v-slot="{hover}">
      <div class="album d-flex justify-center">
        <div class="album__item"
          v-for="(item, index) in items"
          :key="`item_${index}`"
          :style="`transform: rotate(${Math.round(Math.random() * rotation - rotation/2)}deg)` +
       `translate(${Math.round(Math.random() * translation - translation/2) * (hover ? hoverFactor : 1)}%,` +
       ` ${Math.round(Math.random() * translation - translation/2) * (hover ? hoverFactor*0.2 : 1)}%)`"
          @click="$emit('click', index)">
          <div class="img__wrapper">
            <img class="img"
              :src="item"
              :alt="`item_${index}`"
              >
          </div>
          <!-- :style="`background-image: url(${require(`@/assets/img/gallery/` + item)})`"  -->
          <!-- </div> -->
        </div>
      </div>
    </v-hover>
  </div>
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      default: ''
    }
  },
  name: 'AlbumCard',

  data() {
    return {
      rotation: 5, // deg
      translation: 6, // %,
      hoverFactor: 10,
    }
  },
  methods: {
    getImgUrl(pic) {
      return require(pic)
    } 
  }
}
</script>
<style lang="scss"
  scoped>
.album {
  position: relative;
  height: 500px;
  width: 100%;

  &__item {
    cursor: pointer;

    position: absolute;
    max-width: 100%;
    max-height: 100%;
    background-color: white;
    padding: 30px 30px 100px 30px;
    border: 1px solid rgba(#999, 0.4);

    box-shadow: 10px 6px 15px -10px rgba(#111100, 0.4);

    transition: transform .2s ease;


    >.img__wrapper {
      height: 0;
      width: 300px;
      padding-bottom: 300px;
      overflow: hidden;
      border-radius: 2px;

      position: relative;

      >.img {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);

        object-fit: cover;
        height: 100%;
        overflow: hidden;
        //filter: sepia(0.2);

      }

      // &:after {
      //   content: "";
      //   background-image: url(https://fixthephoto.com/images/content/film-scratches-overlay-free-cover1611833654.jpg);
      //   width: 100%;
      //   height: 100%;
      //   opacity: 0.4;
      //   overflow: hidden;
      //   position: absolute;
      //   mix-blend-mode: screen;
      // }
    }
  }
}
</style>