const venews = {
    conacin: {
        name: "Conacín",
        address: "Nataniel Cox 185",
        city: "Santiago"
    },
    raices: {
        name: "Raíces Bar",
        address: "Cueto 816, barrio Yungay",
        city: "Santiago"
    },
    cancha_villa_los_jardines: {
        name: "Cancha Villa los Jardines",
        address: "Rodrigo de Araya 4500",
        city: "Santiago"
    },
    plaza_de_monte_aguila: {
        name: "Plaza de Monte Águila",
        address: "Plaza de Armas Monte Águila",
        city: "Cabrero"
    },
    bar_victoria: {
        name: 'Bar Victoria',
        address: 'Carlos Valdovinos 1951, Pedro Aguirre Cerda',
        city: "Santiago"
    },
    tambo_la_florida: {
        name: 'Lo Cañas, La Florida',
        address: 'Calle 3, Lo Cañas, La Florida',
        city: 'Santiago'
    },
    arena_recoleta: {
        name: 'Arena Recoleta',
        address: 'Einstein 898, Recoleta',
        city: 'Santiago',
    },
    sede_18_penalolen: {
      name: "Sede 18 Lo Hermida",
      address: 'Altiplano 6587, Peñalolén',
      city: 'Santiago'
    }
}

const autoThumb = (event) => ({...event, flyerThumb: `thumb/${event.flyer.replace(/\.(jpe?g|png)$/i, "_thumb.$1")}`})
const autoPlace = (event) => ({...event, place: venews[event.place]})
const dateSort = (first, second) => {
  const [day1, month1, year1] = first.date.split("/")
  const [day2, month2, year2] = second.date.split("/")
  const nfirst = new Date(`${year1}-${month1}-${day1}`).getTime()
  const nsecond = new Date(`${year2}-${month2}-${day2}`).getTime()
  return nfirst < nsecond ? 1 : nfirst == nsecond ? 0 : -1
}

const nextEvents = [
  {
      name: "Tambo Solidario: Todos somos Franco Cabello",
      date: "9/11/2019",
      place: "conacin",
      flyer: "9-11_conacin_franco_cabello.jpg",
  }, {
      name: "1er Encuentro de Pueblos Originarios Ñuñoa",
      date: "23/11/2019",
      place: "cancha_villa_los_jardines",
      flyer: "23-11_ñuñoa.jpg",
  }, {
      name: "Gran encuentro Folclore latinoamericano",
      date: "22/12/2019",
      place: "plaza_de_monte_aguila",
      flyer: "22-12_cabrero.jpg",
  }, {
      name: 'Lanzamiento Disco Sergio Ramírez: "Charango Chileno"',
      date: "28/12/2019",
      place: "conacin",
      flyer: "28-12_checho_ramirez.jpg",
  }, {
      name: 'Tambo + Fiesta Latina en Raíces Bar',
      date: '9/4/2022',
      place: 'raices',
      flyer: '9-4-2022_raices.jpeg',
  }, {
      name: 'Tambo bajo las Estrellas',
      date: '28/5/2022',
      place: 'tambo_la_florida',
      flyer: '28-5-2022_estrellas.jpg',
  }, {
      name: 'Ilpakamani junto a Arak Pacha',
      date: '4/6/2022',
      place: 'bar_victoria',
      flyer: '4-6-2022_arakpacha.jpeg',
  }, {
      name: 'Tambo bajo las Estrellas',
      date: '9/7/2022',
      place: 'arena_recoleta',
      flyer: '9-7-2022_estrellas.jpg',

  }, {
    name: 'Tambo + Fiesta Latina',
    date: '28/1/2023',
    place: 'raices',
    flyer: '28-1-2023_raices.jpeg'
  }, {
    name: 'Lakitas ',
    date: '14/1/2023',
    place: 'sede_18_penalolen',
    flyer: '1-4-2023_bambu.jpeg',
  }, {
    name: 'Lanzamiento nuevo disco instrumental Bambú',
    date: '1/4/2023',
    place: 'sede_18_penalolen',
    flyer: '1-4-2023_bambu.jpeg',
  }

  ].map(autoPlace).map(autoThumb).sort(dateSort)

export default nextEvents