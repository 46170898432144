export default {
    name: 'responsiveText', 
    data() {
        return {

        }
    },
    computed: {
        titleDisplayClass() {
            return this.$vuetify.breakpoint.smAndDown ? 'display-1 mt-5' : 'display-2 mt-2'
        }
    }
}