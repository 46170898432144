<template>
  <div :class="`${titleDisplayClass} text-center font-weight-light mb-8 mt-12`">
    <slot />
  </div>
</template>

<script>
import responsiveText from '@/mixins/responsiveText'
export default {
  mixins: [responsiveText],
  name: 'BaseTitle',
  props: {

  }
}
</script>

<style lang="css" scoped>
</style>