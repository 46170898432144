<template>
  <div>
    <v-container ref="vcontainer" class="mb-12">
      <base-title>Videos</base-title>
      <vueper-slides class="no-shadow"
        lazy
        lazy-load-on-drag
        :slide-ratio="1/4"
        :gap="3"
        :bullets-outside="true"
        :visible-slides="3"
        :autoplay="false"
        :duration="2400"
        :dragging-distance="70"
        :breakpoints="breakpoints">
        <vueper-slide v-for="(video, index) in videos"
          :key="`video_${index}`"
          :image="youtubeThumbnail(video.id)"
          class="slide"
          @click.native="video_overlay_id = video.id" 
          >
          <template v-slot:content>
            <div>
              <v-btn icon
                class="play-button"
                absolute
                rounded
                :style="{top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}">
                <v-icon large color="white">mdi-play</v-icon>
              </v-btn>
              <div class="text-capitalize text-center font-weight-light mt-4 video-title" 
                >{{video.title.toUpperCase()}}</div>
                <!-- :style="`font-size: ${titleSize}px !important;`" -->
            </div>
          </template>
        </vueper-slide>
      </vueper-slides>
      <v-dialog v-model="videoOverlay"
        @click:outside="video_overlay_id = ''"
        width="80vw"
        content-class="overflow-hidden"
        overlay-color="black"
        overlay-opacity="0.9"
        max-height="100%">
        <iframe v-if="videoOverlay"
          v-click-outside="onClose"
          frameborder="0"
          style="height: 45vw; overflow: hidden; margin-bottom: -6px"
          :src="youtubeEmbedded(video_overlay_id)"></iframe>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
// import { Carousel, Slide } from "vue-carousel"
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
  components: {
    // Carousel,
    // Slide
    VueperSlides,
    VueperSlide
  },
  data() {
    return {
      videos: [{
          id: "vUwFjAHsHj8",
          title: "Derecho Vital"
        },
        {
          id: "Gb0AHNhYaS4",
          title: "América"
        },
        {
          id: "948o5ko5y94",
          title: "Sendero"
        },
        {
          id: "vgnMjVXt-SY",
          title: "Vuelve"
        },
        {
          id: "JiME-rkvDFc",
          title: "Siete mundos"
        },
        {
          id: "kIGvw1gEpNI",
          title: "Valicha"
        },
        {
          id: "NfbtlKzNmLg",
          title: "la vida quiere mi vida"
        },
        {
          id: "k3GCKkVjRVE",
          title: "Amanecida"
        },
        {
          id: "6cr15bIMrb4",
          title: "Por ti mujer"
        },
        {
          id: "_5Qq_Mk1uLA",
          title: "mi vida sin ti"
        }
      ],
      video_overlay_id: "",
      vcontainerWidth: 0,

      breakpoints: {
        [this.$vuetify.breakpoint.thresholds.lg]: {
          visibleSlides: 3,
        },
        [this.$vuetify.breakpoint.thresholds.md]: {
          visibleSlides: 2,
          slideRatio: 2/5
        },
        [this.$vuetify.breakpoint.thresholds.sm]: {
          visibleSlides: 1,
          slideRatio: 3/4
        },
        [this.$vuetify.breakpoint.thresholds.xs]: {
          visibleSlides: 1,
          slideRatio: 3/4
        },
      }
    }
  },
  methods: {
    youtubeThumbnail(id) {
      return `https://img.youtube.com/vi/${id}/hqdefault.jpg`
    },
    youtubeEmbedded(id) {
      return `https://www.youtube.com/embed/${id}?autoplay=1`
    },
    onClose() {
      this.videoOverlay = ""
    },
    play(id) {
      this.video_overlay_id = id
    }
  },
  computed: {
    videoOverlay: {
        get() {
            return this.video_overlay_id !== undefined && this.video_overlay_id != ""
        },
        set(value) {
            this.video_overlay_id = value
        }
    },
    // titleSize() {
    //     const width = this.$vuetify.breakpoint.width 
    //   return width < 450 ? 33 : 
    //     width < 768 ? 28 : 
    //     width < 1199 ? 30 : 33

    // }
  },
  mounted() {
    this.$nextTick().then(() => {
      this.vcontainerWidth = this.$refs.vcontainer.clientWidth
    })
  }
}
</script>
<style scoped lang="scss">
.video-thumb {
  background-color: #fff;
  border-radius: 4px;
  transition: opacity 0.3s ease;
}

.video-thumb:hover {
  opacity: 0.7;
}

.video-title {
  position: absolute;
  bottom: 0px;
  padding-bottom: 5px;
  padding-top: 2rem;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  width: 100%;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  font-family: $font-accent;
  font-size: 1.8em;
  font-shadow: 0 0 10px rgba(red, 1);
  background: linear-gradient(0deg, rgba(0,0,0,0.9) 10%, rgba(255,255,255,0) 100%);
}
.play-button {
    ::v-deep .v-icon {
        font-size: 6em !important;
        &::before {
          text-shadow: 0 0 20px black;
        }
    }
}

.slide {
  border-radius: 4px;
  cursor: pointer;
  transition: transform .3s ease, filter .3s ease;
  &:hover {
    transform: scale(1.02);
    filter: brightness(0.8);
  }
}
::v-deep .vueperslides__track {
  padding: 10px;
}
::v-deep .vueperslides__bullet {

  &--active {
    .default {
      background-color: white;
    }
  } 
  .default {
    border: 1px solid white;
    overflow: hidden;
  }
}


</style>
